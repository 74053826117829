import React, { useContext, useEffect } from "react";
import { m, useMotionValue } from "framer-motion";
import { CursorContext, CURSOR_TYPES } from "context/CursorContext";
import classNames from "lib/classNames";
import BrandingIcon from "components/SVG/BrandingIcon";
import BrandingIconTextRealization from "components/SVG/BrandingIconTextRealization";
import BrandingIconTextCaseStudy from "components/SVG/BrandingIconTextCaseStudy";
import { useRouter } from "next/router";

export default function Cursor() {
  const router = useRouter();
  const { hover, type, setHover } = useContext(CursorContext);
  const [x, y] = [useMotionValue(0), useMotionValue(0)];

  useEffect(() => {
    const handleMouseMove = (event) => {
      x.set(event.clientX);
      y.set(event.clientY);
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    setHover(false);
  }, [router.query.slug]);

  return (
    <m.div
      className="fixed -translate-x-1/2 -translate-y-1/2 pointer-events-none z-50 top-1/2 left-1/2"
      style={{ left: x, top: y }}
    >
      <div
        className={classNames(
          "transition-all ease-in-sine duration-300",
          hover ? "opacity-100 scale-100" : "opacity-0 scale-75"
        )}
      >
        {type === CURSOR_TYPES.DEFAULT && (
          <span className="h-3 w-3 rounded-full bg-primary" />
        )}
        {type === CURSOR_TYPES.REALIZATION && (
          <>
            <BrandingIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary drop-shadow w-[75px] h-[75px]" />
            <BrandingIconTextRealization className="text-light drop-shadow w-[200px] h-[200px] animate-spin-slow" />
          </>
        )}
        {type === CURSOR_TYPES.CASE_STUDY && (
          <>
            <BrandingIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary drop-shadow w-[75px] h-[75px]" />
            <BrandingIconTextCaseStudy className="text-light drop-shadow w-[200px] h-[200px] animate-spin-slow" />
          </>
        )}
      </div>
    </m.div>
  );
}
