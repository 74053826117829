import React, { createContext, useEffect, useState } from "react";

export const CursorContext = createContext({});

export const CURSOR_TYPES = {
  REALIZATION: "realization",
  CASE_STUDY: "case_study",
  DEFAULT: "default",
};

export default function CursorProvider({ children }) {
  const [hover, setHover] = useState(false);
  const [type, setType] = useState(CURSOR_TYPES.DEFAULT);

  const startHover = (type = CURSOR_TYPES.DEFAULT) => {
    setType(type);
    setHover(true);
  };

  useEffect(() => {
    if (!hover) {
      const timeout = setTimeout(() => {
        setType(CURSOR_TYPES.DEFAULT);
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [hover]);

  return (
    <CursorContext.Provider
      value={{ hover, type, setHover, setType, startHover }}
    >
      {children}
    </CursorContext.Provider>
  );
}
