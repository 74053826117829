import React from "react";

export default function BrandingIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3500 3500"
      style={{
        enableBackground: "new 0 0 3500 3500",
      }}
      xmlSpace="preserve"
      className={className}
      aria-hidden="true"
    >
      <switch>
        <g>
          <circle fill="currentColor" cx="1750" cy="1750" r="1750" />
          <path
            fill="#212327"
            d="m2149.79 1632.51 322.89-282.3 75.95-66.41V951.36H951.36v398.85h916.4l-730.59 730.6 282.02 282.02 730.6-730.59z"
          />
          <path
            fill="#212327"
            d="M2149.79 1632.51v916.13h398.84v-117.49h.01v-449.93h-.01z"
          />
        </g>
      </switch>
    </svg>
  );
}
